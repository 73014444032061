
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

@Component({
    name: "Giveaway",
    components: {
        VueSlider
    }
})
export default class Giveaway extends Vue {
    error = "";
    mode = "form";
    maps = ["default", "savanah", "forest"];
    mapNames = ["Guinea Pigs Club", "Lion cats", "lazyBearZ"];

    game = {
        map: "default",
        duration: 60, // min 10 - max 120
        participants: 20, // min 1 - max 75
        names: ""
    }

    get map() {
        return this.game.map;
    }

    get duration() {
        return this.game.duration;
    }

    get participants() {
        return this.game.participants;
    }

    get names() {
        return this.game.names.replace(/\s+/g, ' ').trim().split(',').filter(n => n.trim()).map(n => n.trim()).join(',');
    }

    get namesNumber() {
        return this.names.split(',').filter(n => n).length;
    }

    stopGame() {
        this.mode = "form";
    }

    isValid() {
        let valid = true;

        // if (this.names.split(',').length !== this.game.participants) {
        //     this.error = `Game participants number: ${this.game.participants}, entered names number: ${this.names.length}. Please adjust your data.`
        // }

        if (!this.maps.includes(this.game.map)) {
            this.error = "Map not found. Please select our maps !";
        }

        if (this.game.participants < 1 || this.game.participants > 75) {
            this.error = "Participants number out of range."
        }

        return valid;
    }

    launchGame() {
        if (this.isValid()) {
            this.mode = "game";
        }
    }
}
